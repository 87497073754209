body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.plain-button {
  @apply bg-transparent text-black p-0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  button {
    @apply bg-teal-800 text-white hover:bg-teal-600 font-bold pb-0.5 px-2 mx-0.5 rounded disabled:bg-gray-400;
  }
  a {
    @apply text-teal-900 underline visited:text-teal-700;
  }
  input, select {
    @apply border-gray-200 border-2 p-1
  }
  p {
    @apply my-1
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: circle;
  }
  li {
    @apply ml-6
  }
}